import React from 'react';
import './Mashup.css'
import { Col, Container, Row } from 'react-bootstrap';
import mush from '../../images/banner/mush.jpg'
import Count from '../Counter/Count';
// import { Link } from 'react-router-dom';

const Mashup = () => {
    return (
        <div style={{ backgroundColor: "whitesmoke" }}>
            <Container style={{ marginBottom: '40px' }}>
                <Row xs={1} md={1} lg={2}>

                    <Col className='p-0'>
                        <img className='img-fluid' style={{ height: '', width: '', marginBottom: '', borderRadius: "2px" }} src={mush} alt="" />

                    </Col>

                    <Col className='paragrap-style'>
                        <h1 className='mushup-title' style={{ marginBottom: '50px', marginTop: '50px', textAlign:"center" }}>Welcome to
                            The Monchoshoilee Theater</h1>
                        <p style={{ marginBottom: '30px', textAlign: '' }}>
                            When thou shalt be disposed to set me light
                            And place my merit in the eye of scorn,Upon thy side against myself I’ll fight And prove thee virtuous, though thou art forsworn.With mine own weakness being best acquainted,Upon thy part I can set down a story Of faults conceal’d, wherein I am attainted, That thou in losing me shalt win much glory.
                        </p>

                        <p style={{ marginBttom: '50px', textAlign: '' }}>
                        <h3 >William Shakespeare</h3>

                        </p>
                        {/* <Count></Count> */}
                    </Col>

                </Row>
            </Container>

            {/* <Link to="/contact"><button className='btn btn-primary'>Contact Me</button></Link> */}
        </div>
    );
};

export default Mashup;