import React from 'react';
import BactoToTopButton from '../BactToTopButton/BactoToTopButton';
// import { Button } from 'react-bootstrap';
import Banner from '../Banner/Banner';
// import Contact from '../Contact/Contact';
import Love from '../Love/Love';

import Mashup from '../Mashup/Mashup';
import ShortBio from '../ShortBio/ShortBio';
import Involvecarts from '../Involvecarts/Involvecarts';
import GetTiket from '../GetTiket/GetTiket';
// import Webseries from '../Webseries/Webseries';


const Home = () => {

    return (
        <div>
            <Banner></Banner>
            <GetTiket></GetTiket>
            <Involvecarts></Involvecarts>
            {/* <ShortBio></ShortBio> */}
            <Love></Love>
        
            <Mashup></Mashup>
            <BactoToTopButton></BactoToTopButton>
            
        </div>

    );
};

export default Home;