import React, { Component } from "react";
import Slider from "react-slick";
import './Love.css'

import imgg1 from '../../images/thanks/imgg1.jpg'
import imgg2 from '../../images/thanks/imgg2.jpg'
import imgg22 from '../../images/thanks/imgg22.jpg'
import imgg3 from '../../images/thanks/imgg3.jpg'
import imgg4 from '../../images/thanks/imgg4.jpg'
import imgg5 from '../../images/thanks/imgg5.jpg'
import imgg6 from '../../images/thanks/imgg6.jpg'
import imgg7 from '../../images/thanks/imgg7.jpg'
import imgg8 from '../../images/thanks/imgg8.jpg'


export default class PauseOnHover extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover: true
    };
    return (
      <div className="caruseres" style={{backgroundColor:"" ,boxSizing:"border-box", paddingBottom:"80px"}}>


<div style={{backgroundColor:"tomato",boxSizing:"border-box",padding:"35px",paddingLeft:"140px" }} className=" mx-auto">

         <h1 className="one">Monchoshoilee 12 year celebration </h1>
        <Slider {...settings}>
            
        <div className="">
            <img className="img-fluid" width="75%" src={imgg1} alt="" />
          </div>
          <div className="">
            <img className="img-fluid" width="75%" src={imgg2} alt="" />
          </div>
        
          <div className="">
          <img className="img-fluid" width="75%" src={imgg22} alt="" />
          </div>
          <div className="">
          <img className="img-fluid" width="75%" src={imgg3} alt="" />
          </div>
    
          <div className="">
          <img className="img-fluid" width="75%" src={imgg4} alt="" />
          </div>
          <div className="">
          <img className="img-fluid" width="75%" src={imgg5} alt="" />
          </div>
          <div className="">
          <img className="img-fluid" width="75%" src={imgg6} alt="" />
          </div>
          <div className="">
          <img className="img-fluid" width="75%" src={imgg7} alt="" />
          </div>
          <div className="">
          <img className="img-fluid" width="75%" src={imgg8} alt="" />
          </div>
    
        </Slider>
      </div>
      </div>
    );
  }
}