import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './ShortBio.css'
import 'react-html5video/dist/styles.css';
import mochopad from '../../images/banner/Monchoshoilee_Pad.jpg'
import mochokotha from '../../images/banner/monchokotha.jpg'
import monb from '../../images/thanks/monb.jpg'
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';

const ShortBio = () => {


    return (
        <div>
            <Container>
                <Row lg={2} xs={1} md={2} >

                    <Col style={{ marginTop: "" }}>
                        <div >
                            <div>
                            <img className='img-fluid' src={monb} alt="" />
                            </div>
                   
                            <h3 className='headobj'>
                                Aims & Objectives:
                            </h3>

                            <p className='pt-2 amistext'>
                                The aims and objectives of Monchoshoilee are to provide the community with a broadly-
                                based arts group producing high quality plays, music, films, and organising visual and
                                performing arts events. 
                                <Link to="/aimsandobj">Read more </Link>
                            </p>
                        </div>

                    </Col>

                    <Col style={{ marginBottom: "", marginTop: "" }}>
                        <div className=''>
                            <img className='img-fluid' src={mochokotha} alt="" />
                        </div>
                    </Col>


                </Row>
            </Container>
        </div>
    );
};


export default ShortBio;
