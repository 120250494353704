import React from 'react';
import { useEffect, useState } from 'react';
// import { Button } from 'react-bootstrap';
import './BactoToTopButton.css'
import bactoToparow from '../../images/arrows/up-arrow.png'

const BactoToTopButton = () => {

    const [bactoToTopButton, setBackToTopButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 100) {
                setBackToTopButton(true);
            }
            else {
                setBackToTopButton(false);
            }
        });

    }, []);

    const scrollUp = () => {
        window.scrollTo({
            // top:150,
            top: 0,
            behavior: 'smooth'
        })
    }

    return (
        <div >
            {bactoToTopButton && (

                <img style={{ zIndex: '2' }}
                    className='backToTopStyle'
                    onClick={scrollUp}
                    src={bactoToparow} alt="backToToparrow" />
            )}

        </div>
    );
};

export default BactoToTopButton;