import React from 'react';
import './GetTiket.css'
import { Col, Container, Row } from 'react-bootstrap';
import biborno from '../../images/involvements/bkc.jpg'

const GetTiket = () => {
    return (
        <div>
            <div style={{ backgroundColor: "whitesmoke" }}>
                <Container style={{ marginBottom: '' }}>
                    <Row xs={1} md={1} lg={3}>

                        <Col className='paragrap-styles'>
                            <div className='mushup-title' style={{ marginBottom: '50px', marginTop: '30px', textAlign: "justify" }}>
                                <h6><strong>Play Name </strong> : Biborno Krishnochura </h6>
                                <p><strong>Date: </strong> : 05 NOV 23 </p>
                                <p><strong>Day: </strong> : Sunday  </p>
                                <p><strong>Time: </strong> : 7pm  </p>
                                <p><strong>Venue: </strong> : Brady Arts Centre 192-196 Hanbury St London E1 5HU.</p>
                              
                            </div>
                           
                        </Col>

                        <Col className=''>
                            <img className='img-fluid' style={{ height: '', width: '', paddingTop: '60px', marginLeft:"20px", borderRadius: "2px" }} src={biborno} alt="" />

                        </Col>

                        <Col className='p-5'>
                       <div className='mt-5 ms-5'>
                       <h3  className='mushup-title ms-5 mb-4'> Support us</h3>
                        <button style={{width:"300px"}} className='bg-danger'>
                        <a className='text-light'  href="https://www.eventbrite.co.uk/e/biborno-krishnochura-by-monchoshoilee-tickets-731179596857?aff=oddtdtcreator">Get Tikets</a>
                        </button>
                       </div>
                        </Col>



                    </Row>
                </Container>

                {/* <Link to="/contact"><button className='btn btn-primary'>Contact Me</button></Link> */}
            </div>
        </div>
    );
};

export default GetTiket;