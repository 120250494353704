import React from 'react';
import './Tvandweb.css';
// import Image from 'react-bootstrap/Image'
import { Col, Row, Container } from 'react-bootstrap';

import moncholetter from '../../images/banner/moncholetter.jpg';
import eventspic from '../../images/banner/events11.jpg';



const Tvandweb = () => {
    return (
        <div className='web-headings'>
            <h3 className='mt-2 mb-2' style={{}}> Our 12 years celebration event </h3>

            <div>
                <img className='img-fluid' src={eventspic} alt="" />
            </div>



            <div>
                <img className='img-fluid' src={moncholetter} alt="" />
            </div>

            <Container>
                <Row className=''>
                    <Col lg={4} className="">



                    </Col>


                    <Col lg={4}>


                    </Col>

                    <Col lg={4}>

                    </Col>


                </Row>
            </Container>

        </div>
    );
};

export default Tvandweb;