import React from 'react';
import './Movies.css'
import { Col, Container, Row } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import BactoToTopButton from '../BactToTopButton/BactoToTopButton';

import bou1 from '../../images/activitiespic/bow1.jpg'
import bou2 from '../../images/activitiespic/bow2.jpg'
import bason1 from '../../images/activitiespic/bason1.jpg'
import bason2 from '../../images/activitiespic/bason2.jpg'
import depthagras1 from '../../images/activitiespic/deptagras1.jpg'
import depthagras2 from '../../images/activitiespic/deptagras2.jpg'
import bhimbod1 from '../../images/activitiespic/bimbod1.jpg'
import bhimbod2 from '../../images/activitiespic/bimbod2.jpg'
import norok1 from '../../images/activitiespic/norokbas1.jpg'
import norok2 from '../../images/activitiespic/norokbas2.jpg'
import infor1 from '../../images/activitiespic/informar1.jpg'
import infor2 from '../../images/activitiespic/informar2.jpg'
import king1 from '../../images/activitiespic/kingscu1.jpg'
import king2 from '../../images/activitiespic/kingscu2.jpg'
import rin1 from '../../images/activitiespic/rinchud1.jpg'
import rin2 from '../../images/activitiespic/rinchud2.jpg'
import sporda1 from '../../images/activitiespic/sporda1.jpg'
import sporda2 from '../../images/activitiespic/sporda2.jpg'
import gorjmai1 from '../../images/activitiespic/ghorjmai1.jpg'
import gorjmai2 from '../../images/activitiespic/ghorjmai2.jpg'
import rakkos1 from '../../images/activitiespic/rakkos1.jpg'
import rakkos2 from '../../images/activitiespic/rakkos2.jpg'
import rakkos3 from '../../images/activitiespic/rakkos3.jpg'
import rakkos4 from '../../images/activitiespic/rakkos4.jpg'

const Movies = () => {
    return (
        <div style={{ backgroundColor: "black" }}>

            <div className="movies-titles" >
                <p> Our Activities & Projects </p>
            </div>

            <Container className='paapa'>
                <Row className='mb-4'>

                    <Col lg={8} md={4} xs={12}>

                        <div style={{textAlign:"left", paddingLeft:""}}>
                            <p>  <strong>Drama </strong> : BOU  </p>
                            <p> <strong>  Playwriter</strong> : Mannan Hira</p>
                            <p> <strong> Director</strong> : Ziaur Rahman Saklen</p>
                            <p><strong>First Show</strong> : April 2010 </p>
                        </div>
                        <Row className='mt-3'>

                            <Col lg={6} md={6} xs={12}>
                                <img style={{ width: "350px" }} src={bou1} alt="" />
                            </Col>
                            <Col lg={6} md={6} xs={12}>
                                <img style={{ width: "350px" }} src={bou2} alt="" />
                            </Col>
                        </Row>

                    </Col>

                    <Col lg={4} md={4} xs={12}>

                        {/* <Row className='mb-3'>

                            <h1 style={{ color: "#d04764", fontFamily:"lobster" , fontSize: "46px", fontWeight: "normal", lineHeight: "48px", margin: "0 0 18px", textShadow: "1px 0px 0px #fff"}}>
                            Prjects comming soon......
                            </h1>
                                    
                        </Row> */}

                    </Col>

                </Row>

                {/* ===================== */}
                <Row className='mb-4'>

                    <Col lg={8} md={4} xs={12}>

                        <div style={{textAlign:"left", paddingLeft:""}}>
                            <p>  <strong>Drama </strong> : BASON  </p>
                            <p> <strong>  Playwriter</strong> : Salim Aldin</p>
                            <p> <strong> Director</strong> : Ziaur Rahman Saklen</p>
                            <p><strong>First Show</strong> : November 2010 </p>
                        </div>
                        <Row className='mt-3'>

                            <Col lg={6} md={6} xs={12}>
                                <img style={{ width: "350px" }} src={bason1} alt="" />
                            </Col>
                            <Col lg={6} md={6} xs={12}>
                                <img style={{ width: "350px" }} src={bason2} alt="" />
                            </Col>
                        </Row>

                    </Col>

                    <Col lg={4} md={4} xs={12}>

                        <Row className='mb-3'>

                            {/* <h1 style={{ color: "#d04764", fontFamily:"lobster" , fontSize: "46px", fontWeight: "normal", lineHeight: "48px", margin: "0 0 18px", textShadow: "1px 0px 0px #fff"}}>
                            Prjects comming soon......
                            </h1> */}
                                    
                        </Row>

                    </Col>

                </Row>
                {/* =================================== */}
                <Row className='mb-4'>

                    <Col lg={8} md={4} xs={12}>

                        <div style={{textAlign:"left", paddingLeft:""}}>
                            <p>  <strong>Drama </strong> : Debotar Grash  </p>
                            <p> <strong>  Playwriter</strong> : Robindranath Thakur</p>
                            <p> <strong> Director</strong> : Ziaur Rahman Saklen</p>
                            <p><strong>First Show</strong> : may 2011 </p>
                        </div>
                        <Row className='mt-3'>

                            <Col lg={6} md={6} xs={12}>
                                <img style={{ width: "350px" }} src={depthagras1} alt="" />
                            </Col>
                            <Col lg={6} md={6} xs={12}>
                                <img style={{ width: "350px" }} src={depthagras2} alt="" />
                            </Col>
                        </Row>

                    </Col>

                    <Col lg={4} md={4} xs={12}>

                        <Row className='mb-3'>

                            {/* <h1 style={{ color: "#d04764", fontFamily:"lobster" , fontSize: "46px", fontWeight: "normal", lineHeight: "48px", margin: "0 0 18px", textShadow: "1px 0px 0px #fff"}}>
                            Prjects comming soon......
                            </h1> */}
                                    
                        </Row>

                    </Col>

                </Row>
                {/* =================== */}
                <Row className='mb-4'>

                    <Col lg={8} md={4} xs={12}>

                        <div style={{textAlign:"left", paddingLeft:""}}>
                            <p>  <strong>Drama </strong> : Bhimbodh  </p>
                            <p> <strong>  Playwriter</strong> :  Narayan Ghoghupadhay</p>
                            <p> <strong> Director</strong> : Ziaur Rahman Saklen</p>
                            <p><strong>First Show</strong> : may 2011 </p>
                        </div>
                        <Row className='mt-3'>

                            <Col lg={6} md={6} xs={12}>
                                <img style={{ width: "350px" }} src={bhimbod1} alt="" />
                            </Col>
                            <Col lg={6} md={6} xs={12}>
                                <img style={{ width: "350px" }} src={bhimbod2} alt="" />
                            </Col>
                        </Row>

                    </Col>

                    <Col lg={4} md={4} xs={12}>

                        <Row className='mb-3'>

                            {/* <h1 style={{ color: "#d04764", fontFamily:"lobster" , fontSize: "46px", fontWeight: "normal", lineHeight: "48px", margin: "0 0 18px", textShadow: "1px 0px 0px #fff"}}>
                            Prjects comming soon......
                            </h1> */}
                                    
                        </Row>

                    </Col>

                </Row>
                {/* =============== */}

                <Row className='mb-4'>

                    <Col lg={8} md={4} xs={12}>

                        <div style={{textAlign:"left", paddingLeft:""}}>
                            <p>  <strong>Drama </strong> : Norokh Bash  </p>
                            <p> <strong>  Playwriter</strong> : Robindranath Thakur</p>
                            <p> <strong> Director</strong> : Ziaur Rahman Saklen</p>
                            <p><strong>First Show</strong> : November 2011 </p>
                        </div>
                        <Row className='mt-3'>

                            <Col lg={6} md={6} xs={12}>
                                <img style={{ width: "350px" }} src={norok1} alt="" />
                            </Col>
                            <Col lg={6} md={6} xs={12}>
                                <img style={{ width: "350px" }} src={norok2} alt="" />
                            </Col>
                        </Row>

                    </Col>

                    <Col lg={4} md={4} xs={12}>

                        <Row className='mb-3'>

                            {/* <h1 style={{ color: "#d04764", fontFamily:"lobster" , fontSize: "46px", fontWeight: "normal", lineHeight: "48px", margin: "0 0 18px", textShadow: "1px 0px 0px #fff"}}>
                            Prjects comming soon......
                            </h1> */}
                                    
                        </Row>

                    </Col>

                </Row>
                {/* ================= */}
                <Row className='mb-4'>

                    <Col lg={8} md={4} xs={12}>

                        <div style={{textAlign:"left", paddingLeft:""}}>
                            <p>  <strong>Drama </strong> : Informer   </p>
                            <p> <strong>  Playwriter</strong> : Shantonu Bishwash</p>
                            <p> <strong> Director</strong> : Ziaur Rahman Saklen</p>
                            <p><strong>First Show</strong> : November 2013 </p>
                        </div>
                        <Row className='mt-3'>

                            <Col lg={6} md={6} xs={12}>
                                <img style={{ width: "350px" }} src={infor1} alt="" />
                            </Col>
                            <Col lg={6} md={6} xs={12}>
                                <img style={{ width: "350px" }} src={infor2} alt="" />
                            </Col>
                        </Row>

                    </Col>

                    <Col lg={4} md={4} xs={12}>

                        <Row className='mb-3'>

                            {/* <h1 style={{ color: "#d04764", fontFamily:"lobster" , fontSize: "46px", fontWeight: "normal", lineHeight: "48px", margin: "0 0 18px", textShadow: "1px 0px 0px #fff"}}>
                            Prjects comming soon......
                            </h1> */}
                                    
                        </Row>

                    </Col>

                </Row>
                {/* ============= */}
                <Row className='mb-4'>

                    <Col lg={8} md={4} xs={12}>

                        <div style={{textAlign:"left", paddingLeft:""}}>
                            <p>  <strong>Drama </strong> :  Kings Courier  </p>
                            <p> <strong>  Playwriter</strong> : Binchupi Chowdhury</p>
                            <p> <strong> Director</strong> : Ziaur Rahman Saklen</p>
                            <p><strong>First Show</strong> : November 2014 </p>
                        </div>
                        <Row className='mt-3'>

                            <Col lg={6} md={6} xs={12}>
                                <img style={{ width: "350px" }} src={king1} alt="" />
                            </Col>
                            <Col lg={6} md={6} xs={12}>
                                <img style={{ width: "350px" }} src={king2} alt="" />
                            </Col>
                        </Row>

                    </Col>

                    <Col lg={4} md={4} xs={12}>

                        <Row className='mb-3'>

                            {/* <h1 style={{ color: "#d04764", fontFamily:"lobster" , fontSize: "46px", fontWeight: "normal", lineHeight: "48px", margin: "0 0 18px", textShadow: "1px 0px 0px #fff"}}>
                            Prjects comming soon......
                            </h1> */}
                                    
                        </Row>

                    </Col>

                </Row>
                {/* ======================= */}
                <Row className='mb-4'>

                    <Col lg={8} md={4} xs={12}>

                        <div style={{textAlign:"left", paddingLeft:""}}>
                            <p>  <strong>Drama </strong> :  Rinshud   </p>
                            <p> <strong>  Playwriter</strong> : Robindranath Thakur</p>
                            <p> <strong> Director</strong> : Ziaur Rahman Saklen</p>
                            <p><strong>First Show</strong> : November 2015 </p>
                        </div>
                        <Row className='mt-3'>

                            <Col lg={6} md={6} xs={12}>
                                <img style={{ width: "350px" }} src={rin1} alt="" />
                            </Col>
                            <Col lg={6} md={6} xs={12}>
                                <img style={{ width: "350px" }} src={rin2} alt="" />
                            </Col>
                        </Row>

                    </Col>

                    <Col lg={4} md={4} xs={12}>

                        <Row className='mb-3'>

                            {/* <h1 style={{ color: "#d04764", fontFamily:"lobster" , fontSize: "46px", fontWeight: "normal", lineHeight: "48px", margin: "0 0 18px", textShadow: "1px 0px 0px #fff"}}>
                            Prjects comming soon......
                            </h1> */}
                                    
                        </Row>

                    </Col>

                </Row>
                {/* ==================== */}
                <Row className='mb-4'>

                    <Col lg={8} md={4} xs={12}>

                        <div style={{textAlign:"left", paddingLeft:""}}>
                            <p>  <strong>Drama </strong> :  Spordha   </p>
                            <p> <strong>  Playwriter</strong> : Abdullah Al Mamun</p>
                            <p> <strong> Director</strong> : Rajib Das Raju</p>
                            <p><strong>First Show</strong> : November 2016 </p>
                        </div>
                        <Row className='mt-3'>

                            <Col lg={6} md={6} xs={12}>
                                <img style={{ width: "350px" }} src={sporda1} alt="" />
                            </Col>
                            <Col lg={6} md={6} xs={12}>
                                <img style={{ width: "350px" }} src={sporda2} alt="" />
                            </Col>
                        </Row>

                    </Col>

                    <Col lg={4} md={4} xs={12}>

                        <Row className='mb-3'>

                            {/* <h1 style={{ color: "#d04764", fontFamily:"lobster" , fontSize: "46px", fontWeight: "normal", lineHeight: "48px", margin: "0 0 18px", textShadow: "1px 0px 0px #fff"}}>
                            Prjects comming soon......
                            </h1> */}
                                    
                        </Row>

                    </Col>

                </Row>
                {/* =================== */}
                <Row className='mb-4'>

                    <Col lg={8} md={4} xs={12}>

                        <div style={{textAlign:"left", paddingLeft:""}}>
                            <p>  <strong>Drama </strong> :  Ghorjamai   </p>
                            <p> <strong>  Playwriter</strong> :  Moliye </p>
                            <p> <strong> Director</strong> :  Ziaur Rahman Saklen</p>
                            <p><strong>First Show</strong> : November 2017 </p>
                        </div>
                        <Row className='mt-3'>

                            <Col lg={6} md={6} xs={12}>
                                <img style={{ width: "350px" }} src={gorjmai1} alt="" />
                            </Col>
                            <Col lg={6} md={6} xs={12}>
                                <img style={{ width: "350px" }} src={gorjmai2} alt="" />
                            </Col>
                        </Row>

                    </Col>

                    <Col lg={4} md={4} xs={12}>

                        <Row className='mb-3'>

                            {/* <h1 style={{ color: "#d04764", fontFamily:"lobster" , fontSize: "46px", fontWeight: "normal", lineHeight: "48px", margin: "0 0 18px", textShadow: "1px 0px 0px #fff"}}>
                            Prjects comming soon......
                            </h1> */}
                                    
                        </Row>

                    </Col>

                </Row>
                {/* ==================== */}
                <Row className='mb-4'>

                    <Col lg={8} md={4} xs={12}>

                        <div style={{textAlign:"left", paddingLeft:""}}>
                            <p>  <strong>Drama </strong> :  Mesh o Rakkosh    </p>
                            <p> <strong>  Playwriter</strong> :  Monuj Mithroh </p>
                            <p> <strong> Director</strong> :  Ziaur Rahman Saklen</p>
                            <p><strong>First Show</strong> : November 2019 </p>
                        </div>
                        <Row className='mt-3'>

                            <Col lg={6} md={6} xs={12}>
                                <img style={{ width: "350px" }} src={rakkos1} alt="" />
                            </Col>
                            <Col lg={6} md={6} xs={12}>
                                <img style={{ width: "350px" }} src={rakkos2} alt="" />
                            </Col>
                        </Row>
                        <Row className='mt-3'>

                            <Col lg={6} md={6} xs={12}>
                                <img style={{ width: "350px" }} src={rakkos3} alt="" />
                            </Col>
                            <Col lg={6} md={6} xs={12}>
                                <img style={{ width: "350px" }} src={rakkos4} alt="" />
                            </Col>
                        </Row>

                    </Col>

                    <Col lg={4} md={4} xs={12}>

                        <Row className='mb-3'>

                            {/* <h1 style={{ color: "#d04764", fontFamily:"lobster" , fontSize: "46px", fontWeight: "normal", lineHeight: "48px", margin: "0 0 18px", textShadow: "1px 0px 0px #fff"}}>
                            Prjects comming soon......
                            </h1> */}
                                    
                        </Row>

                    </Col>

                </Row>

                <Fade left>

                </Fade>
                <Fade right>

                </Fade>


            </Container>
            <Flip left>

            </Flip>
            <BactoToTopButton></BactoToTopButton>
        </div>
    );
};

export default Movies;