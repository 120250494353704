import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/monchoshoilee-logo1.png'
import './Header.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import { Button } from 'react-bootstrap';

const Header = () => {

    return (

        <div>
            <Navbar collapseOnSelect expand="lg" style={{backgroundColor:" #F9EBF5 "}} sticky="top" className='p-0 m-0'>

            <Navbar.Toggle style={{backgroundColor:"whitesmoke"}} aria-controls="responsive-navbar-nav" />

                <Container>
                   <div className='text-start img-contrl'>
                   <Navbar.Brand  as={Link} to="/home"> 
                    <img  style={{width:"50%" }} src={logo} alt="" />
                     </Navbar.Brand >
                   </div>
              

                <div className='text-sizing'>
                <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link className='hlink' as={Link} to="/home">Home</Nav.Link>
                            <Nav.Link className='hlink' as={Link} to="/about">About_Us</Nav.Link>
                            <Nav.Link className='hlink' as={Link} to="/movies">Activities</Nav.Link>
                            <Nav.Link className='hlink' as={Link} to="/tvandweb">Events</Nav.Link>
                            <Nav.Link className='hlink' as={Link} to="/gallery">Gallery</Nav.Link>
                            <Nav.Link className='hlink' as={Link} to="/contact">Contact</Nav.Link>
                        </Nav>

                        
                    </Navbar.Collapse>
                </div>
 
                    <div className='fb-tube'>
                       <div className='pe-3'>
                       <a href="https://www.facebook.com/Monchoshoilee">
                            <i className='fa fa-facebook text-danger fs-5'></i>
                        </a>
                       </div>

                       <div className='ps-3'>
                       <a href="https://www.facebook.com/Monchoshoilee">
                            <i class="fa fa-youtube text-danger fs-5"></i> 
                        </a>
                       </div>

                    </div>
                   
                </Container>
            </Navbar>
        </div>
    );
};

export default Header;


// -------------Dropdown Menubar-------------//

// <NavDropdown title="Filmography" id="collasible-nav-dropdown">
// <NavDropdown.Item href="#action/3.1">Movies</NavDropdown.Item>
// <NavDropdown.Item href="#action/3.2">
//   Drama
// </NavDropdown.Item>
// <NavDropdown.Item href="#action/3.3">Web Series</NavDropdown.Item>
// <NavDropdown.Divider />
// <NavDropdown.Item href="#action/3.4">
//   Tv Series
// </NavDropdown.Item>
// </NavDropdown>


//----------------Simple Navbar without responsiveness------------//

// <nav className='header '>
// <img  src={logo} alt="" />
// <div className=''>
// <NavLink   to="/home">Home</NavLink>
// <NavLink   to="/about" >About</NavLink>
// <NavLink   to="/movies">My Movies</NavLink>
// <NavLink   to="/webseries">Drama & Web</NavLink>
// <NavLink className='getbutton' to="/contact">Get In Touch</NavLink>
// </div>
// </nav>