import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './About.css'
import { MessengerChat } from "react-messenger-chat-plugin";
import BactoToTopButton from '../BactToTopButton/BactoToTopButton';
import mems from '../../images/banner/mochomem.jpg'
import mems2 from '../../images/banner/members2.jpg'
import ShortBio from '../ShortBio/ShortBio';


const About = () => {
    return (
        <div>

            <div>
                <ShortBio></ShortBio>
            </div>

            <div className=''>
                <img src={mems} width="1200px" alt=""/>
            </div>
        
            <div>
            <img src={mems2} width="1200px" alt=""/>
            </div>
            <hr />

            <div className='maseenger-style'>
                <MessengerChat
                    pageId=" 103128802660083"
                    language="en-US"
                    themeColor={"#4169E1"}
                    bottomSpacing={20}
                    loggedInGreeting="Hello, how can I help you?"
                    loggedOutGreeting="loggedOutGreeting"
                    greetingDialogDisplay={"hide"}
                    greetingDialogDelay={0}
                    debugMode={true}
                    onMessengerShow={() => {

                    }}
                    onMessengerHide={() => {

                    }}
                    onMessengerDialogShow={() => {

                    }}
                    onMessengerDialogHide={() => {

                    }}
                    onMessengerMounted={() => {

                    }}
                    onMessengerLoad={() => {

                    }}
                />
            </div>

                  
           <div>
           <BactoToTopButton></BactoToTopButton>
           </div>

        </div>
    );
};

export default About;
