import React from 'react';
import './Gallery.css';
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import Carousel from 'react-bootstrap/Carousel';
import { Col, Container, Row } from 'react-bootstrap';
import sing from '../../images/Gallery/singing1.jpg'
import dance from '../../images/Gallery/dance.jpg'
import art from '../../images/Gallery/arts.jpg'
import dram from '../../images/Gallery/drama.jpg'
import { Fade } from 'react-reveal';

import bt1 from '../../images/activitiespic/pic44.jpg'
import bt3 from '../../images/activitiespic/pic66.jpg'
import bt8 from '../../images/activitiespic/pic11.jpg'
import bt7 from '../../images/activitiespic/pic33.jpg'
import bt5 from '../../images/activitiespic/pic55.jpg'
import bt9 from '../../images/activitiespic/pic22.jpg'
// import gfg1 from '../../images/film/gfg/gfg1.jpg'
import gfg2 from '../../images/film/gfg/gfg2.jpg'
import gfg3 from '../../images/film/gfg/gfg3.jpg'
import ob2 from '../../images/activitiespic/pic1.jpg'
import ob6 from '../../images/activitiespic/pic3.jpg'
import ob4 from '../../images/activitiespic/pic6.jpg'
import ob3 from '../../images/activitiespic/pic5.jpg'
import ob5 from '../../images/activitiespic/pic2.jpg'


const Gallery = () => {
    return (
        <div  style={{backgroundColor:"black"}}>

            <div className='gallery-headings'>
                <h2 >Welcome To Our Theatre Gallery</h2>
            </div>

            <div className='gellery-style'>

                <Container>
                    <Row>
                        <Col lg={6} xs={12} md={6} style={{ marginBottom: "30px" }}>
                            <div>
                                <div>
                                    <img className='img-fluid' src={sing} alt="" />
                                </div>

                                <div className='mt-4'>
                                    <h3 style={{color:"tomato", fontFamily:"fantasy", fontStyle:"italic", fontSize:"32px"}}>
                                        Singing & Dances
                                    </h3>
                                </div>

                            </div>
                        </Col>


                        <Col lg={6} xs={12} md={6} className="mt-4 mx-auto">

                        <div>
                              
                                </div>
                            <div>
                                <div style={{marginTop:"100px"}}>
                                    <h2 style={{color:"tomato", fontFamily:"fantasy", fontStyle:"italic", fontSize:"32px",paddingBottom:"20px"}}>Every day Sing and Dance and feel the Joy</h2>
                                    <img className='img-fluid' src={dance} alt="" />
                                </div>

                              

                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} xs={12} md={6} style={{ marginBottom: "30px" }}>
                            <div>
                                <div>
                                    <img className='img-fluid' src={art} alt="" />
                                </div>

                                <div className='mt-4'>
                                <h3 style={{color:"tomato", fontFamily:"fantasy", fontStyle:"italic", fontSize:"32px"}}>
                                        Arts & Drama
                                    </h3>
                                </div>

                            </div>
                        </Col>


                        <Col lg={6} xs={12} md={6} className="mt-4 mx-auto">

                            <div>
                                <div style={{marginTop:"100px"}}>
                                    <h2 style={{color:"tomato", fontFamily:"fantasy", fontStyle:"italic", fontSize:"32px",paddingBottom:"20px"}}>Drama starts where logic ends</h2>
                                    <img className='img-fluid' src={dram} alt="" />
                                </div>

                                <div  className='mt-4'>
                                
                                </div>

                            </div>

                        </Col>
                    </Row>
                </Container>

            </div>



            <div >
                <Container>
                    <Row>

                        <Col lg={4} md={4} xs={12} style={{ marginBottom: "10px" }}>


                        </Col>

                        <Col lg={4} md={4} xs={12} style={{ marginBottom: "10px" }}>

                        </Col>

                        <Col lg={4} md={4} xs={12} style={{ marginBottom: "10px" }}>

                        </Col>
                    </Row>
                </Container>
            </div>

            <div>
            <Container >

            <h2 style={{color:"goldenrod", fontFamily:"sans-serif"}}>21may2022 london , drama show singing performance</h2>

                <Row >
                  
                    <Col lg={4} md={4} xs={12}>

                        <Row className='mt-3'>
                            <img style={{}} src={ob3} alt="" />
                            
                        </Row>

                        <Row className='mt-3'>
                            <img style={{}} src={ob5} alt="" />
                           
                        </Row>


                    </Col>
                    <Col lg={4} md={4} xs={12}>

                        <Row className='mb-3'>
                            <img style={{}} src={ob6} alt="" />
                            
                        </Row>

                    </Col>

                    <Col lg={4} md={4} xs={12}>
                        <Row className='mt-3'>
                            <img style={{}} src={ob2} alt="" />
                            
                        </Row>
                        <Row className='mt-3'>
                            <img style={{}} src={ob4} alt="" />
                           
                        </Row>
                    </Col>
                </Row>

                
                <h3 class="mt-2 mb-4 text-secondary">Birmingham show drama " informar " 2022</h3>

                <Row  className="">
                
                   <Col lg={4} md={4} xs={12}>
                      
                        <Row>
                        <img style={{ }} src={bt9} alt="" />
                        <h6>On Acting</h6>
                        </Row>
                        <Row>
                        <img style={{ }} src={bt3} alt="" />
                        <h6>On Acting</h6>
                        </Row>
                       
                    </Col>
                    

                    <Col lg={4} md={4} xs={12}>
                    <Row>
                    <img style={{ }} src={bt7} alt="" />
                        <h6>On Acting</h6>
                     </Row>
                 
                    </Col>


                    <Col lg={4} md={4} xs={12}>
                    <Row>
                    <img style={{ }} src={bt8} alt="" />
                        <h6>On Acting</h6>
                     </Row>
                      <Row>
                      <img style={{ }} src={bt1} alt="" />
                        <h6>On Acting</h6>
                      </Row>
                 
                    </Col>
               
                </Row>



                

              

                <Fade left>
                   
                </Fade>
                <Fade right>
                  
                </Fade>

           
            </Container>
            </div>
        </div>

    );
};

export default Gallery;