import React from 'react';
import './Banner.css'
import ban1 from '../../images/banner/banner2.jpg'
import ban2 from '../../images/banner/banner3.jpg'
import ban3 from '../../images/banner/banner11.jpg'
// import { Link } from 'react-router-dom';

const Banner = () => {

 

  return (
   <div className=''>
<div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={ban1} height="600px" class="d-block w-100" alt="..."/>
      {/* <div class="carousel-caption d-none d-md-block">
        <h5>First slide label</h5>
        <p>Some representative placeholder content for the first slide.</p>
      </div> */}
    </div>
    <div class="carousel-item">
      <img src={ban2} height="600px" class="d-block w-100" alt="..."/>
      {/* <div class="carousel-caption d-none d-md-block">
        <h5>Second slide label</h5>
        <p>Some representative placeholder content for the second slide.</p>
      </div> */}
    </div>
    <div class="carousel-item">
      <img src={ban3} height="600px" class="d-block w-100" alt="..."/>
      {/* <div class="carousel-caption d-none d-md-block">
        <h5>Third slide label</h5>
        <p>Some representative placeholder content for the third slide.</p>
      </div> */}
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually">Previous</span>
  </button>
  
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually">Next</span>
    {/* <span class="visually-hidden">Next</span> */}
  </button>
</div>
   </div>
  );
};

export default Banner;