import React from 'react';
import './Aimsandobjs.css'

const AimsandObj = () => {
    return (
        <div className='container'>
            <h3 className='headobjs'>
                Aims & Objectives:
            </h3>

            <p className='pt-3 ps-5 pe-5 pb-5 amistexts'>
                The aims and objectives of Monchoshoilee are to provide the community with a broadly-
                based arts group producing high quality plays, music, films, and organising visual and
                performing arts events. The group draws its members mostly from Bangladeshi community
                but is open to all irrespective of age, sex, religion, ability or disability. It aims at dealing with
                identity, culture, heritage, integration, well-being as well as enabling members to develop
                interpersonal skills such as confidence, social interaction, responsibility sharing, planning,
                decision making, team work and problem solving so that members feel empowered to
                express themselves in ways beyond their everyday experiences. It also aims to create
                opportunities for audiences to experience new, innovative and traditional performances.

            </p>
        </div>
    );
};

export default AimsandObj;