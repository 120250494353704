import React from 'react';
import './Footer.css'

const Footer = () => {
    return (
        <div>
            <div className='footerStyle'>

                <div className='firstcol ' style={{textAlign:""}}>
                    <h4> Contact || Information</h4> 
                    <p>  <i className="fas fa-envelope"> info@monchoshoilee.com </i></p>
                    <p>  <i className="fas fa-envelope"> monchoshoilee@gmail.com </i></p>
                    <p> <i className="fas fa-phone-alt "> 07846374846, 07538021495 </i> </p>
                    <i className="fas fa-map-marker-alt">
                        <span className="ms-2">22 Violet Road,</span> 
                 
                        <span className=" ms-2">London E3 3QH.</span>
                    </i>
                </div>

                <div className='support-style'>
                    <h4>Working On</h4> 
                    <p >Movie | Music</p>
                    <p >TV & Web Series </p>
                    <p >Drama | Documentary</p>
                    

                </div>

                <div className='direction-set'>
                    <h4>Quick Links</h4><br />
                    <a className='btn-direction' href="#">Get Direction</a>

                    <p className="fs-4 fabicons"> <br />
                        <a href="https://www.facebook.com/Monchoshoilee"> <i className="fab fa-facebook me-4"> </i>{"  "} </a>
                        <a href=" "> <i className="fab fa-instagram me-2"> </i>{"  "}</a>
                        <a href=" "> <i className="fab fa-twitter ms-4"> </i>{"  "}</a>
                        <a href="https://www.facebook.com/Monchoshoilee"> <i className="fab fa-youtube ms-4"> </i>{"  "}</a>
                    </p>

                </div>

            </div>

            <div className='footerLast'>
                <h6>© 2023 Monchoshoilee. All rights reserved.</h6>
            </div>
        </div>
    );
};

export default Footer;