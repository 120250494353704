
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './components/Home/Home.js';
import About from './components/About/About.js'
import Movies from './components/Movies/Movies.js'
// import Webseries from './components/Webseries/Webseries.js'
import Notfound from './components/NotFound/Notfound';
import Header from './components/Header/Header';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Tvandweb from './components/Tvandweb/Tvandweb';
import Gallery from './components/Gallery/Gallery';
import AimsandObj from './components/AimsandObj/AimsandObj';

function App() {
  return (
    <div className="App">
       <Header></Header>
      <Routes>
      <Route path="/home" element={<Home/>}>  </Route>
      <Route path="/" element={<Home/>}>  </Route>
      <Route path="/about" element={<About/>}>  </Route>
      <Route path="/movies" element={<Movies/>}>  </Route>
      <Route path="/tvandweb" element={<Tvandweb/>}>  </Route>
      <Route path="/gallery" element={<Gallery/>}>  </Route>
      <Route path="/contact" element={<Contact/>}>  </Route>
      <Route path="/aimsandobj" element={<AimsandObj/>}>  </Route>
      <Route path='/*' element={<Notfound/>}></Route>
     </Routes>
     <Footer></Footer> 
    </div>
  );
}

export default App;
