import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Contact.css';
import HeadShake from 'react-reveal/HeadShake';
import BactoToTopButton from '../BactToTopButton/BactoToTopButton';
import conts from '../../images/banner/conts.jpg'

const Contact = () => {
    return (
        <div>
            <HeadShake>
                <h5 className='title-style '>Together we make things Beautiful
                    <span>
                        <svg class="arrows">
                            <path class="a1" d="M0 0 L30 32 L60 0"></path>
                            <path class="a2" d="M0 20 L30 52 L60 20"></path>
                            <path class="a3" d="M0 40 L30 72 L60 40"></path>
                        </svg>
                    </span>
                </h5>
            </HeadShake>

            <Container className='container-style'>
                <Row >
                    <Col className='mt-5'>
                        <img src={conts} className='img-fluid' alt="" />
                         
                    </Col>
                    <Col lg={7} md={6} xs={12}>
                        <div class="form-container">
                            <h2 className='contact-name'>Contact Us Via Mail</h2>
                            <form method="POST" action="https://formspree.io/f/xqkvjknd" name="contact" data-netlify="true">

                                <p className='naming-style' > <label for="firstname">Full Name </label>
                                    <input type="text" id="firstname" name="Firstname" placeholder="Your name.." required />
                                </p>
                                <p className='naming-style'>
                                    <label for="email">Email </label>
                                    <input type="email" id="email" name="Email" placeholder="Your email.." required />
                                </p>
                                <div class="d-flex ">
                                    <p class="me-2 text-start" style={{ fontFamily: "Times New Roman" }}>
                                        <label for="contact">Contact No (optional)</label>
                                        <input type="telephone" id="contact" name="Contact" placeholder="Your contact.." />
                                    </p>
                                    <p class="ms-2 text-start" style={{ fontFamily: "Times New Roman" }}> <label for="subject">Subject </label>
                                        <input type="text" id="subject" name="Subject" placeholder="Your topic.." />
                                    </p>
                                </div>
                                <p className='naming-style' >
                                    <label for="message">Message </label>
                                    <textarea id="message" name="Subject" placeholder="The Message you want to give me.." required></textarea>
                                </p>
                                <p className='' style={{ textAlign: 'right' }}>
                                    <button type="submit"> Send Message <span class="fa fa-paper-plane"></span> </button>

                                </p>
                           
                            </form>
                        </div>
                    </Col>

                </Row>



            </Container>     
            <BactoToTopButton></BactoToTopButton>
        </div>
    );
};

export default Contact;


