import React from 'react';
import './Involecartgesign.css'
import { Col, Container, Row } from 'react-bootstrap';
import tsc from '../../images/involvements/theater.png'
import work from '../../images/involvements/workshops.png'
import musc from '../../images/involvements/music-sign.png'
import edu from '../../images/involvements/eduation.png'
import culp from '../../images/involvements/cultural.png'
import youi from '../../images/involvements/youthidea.png'


const Involvecarts = () => {
    return (
        <div className='visaCardFull'>
        <div className='visacard-title container'>
          
            <h1 className='pb-4'>Our Involvement
            </h1>
        </div>


        <Container>
            <Row lg={3} md={2} xs={1}>
            <Col>
                    <div className='visacard-card'>
                        <div className='visacardImg'>
                            <img className='img-fluid' width="50%" src={tsc} alt="education card" />
                        </div>
                        <div>
                            <h4>Theatre School</h4>
                            <p>A drama school or theatre school is an undergraduate and/or graduate school or department at a college or university; or a 
                                Drama section at the Juilliard School.
                            <div style={{textAlign:"end"}}>
                                <a style={{color:"orange"}} href="#">read more</a>
                            </div>
                            </p>
                           
                            
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className='visacard-card'>
                        <div className='visacardImg'>
                            <img className='img-fluid' width="50%" src={work} alt="education card" />
                        </div>
                        <div className='visaCard-allText'>
                            <h4>Workshop</h4>
                            <p>A workshopv is relatively small group of people. This group of people, with the same interests, normally gather to discuss and research on a specific topic or project.
                            <div style={{textAlign:"end"}}>
                                <a style={{color:"orange"}} href="#">read more</a>
                            </div>
                            </p>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className='visacard-card'>
                        <div className='visacardImg'>
                            <img className='img-fluid' width="50%" src={musc} alt="education card" />
                        </div>
                        <div>
                            <h4>Music Class</h4>
                            <p>Along with musical notation, students learn rhythmic techniques—like controlling tempo and the theory of harmony, including chords and key signatures. 
                            <div style={{textAlign:"end", color:"orange"}}>
                                <a style={{color:"orange"}} href="#">read more</a>
                            </div>
                            </p>
                        </div>
                    </div>
                </Col>

           
            </Row>

            <br /> <br />
            <Row lg={3} md={2} xs={1}>
                <Col>
                    <div className='visacard-card'>
                        <div className='visacardImg'>
                            <img className='img-fluid' width="50%" src={edu} alt="education card" />
                        </div>
                        <div>
                            <h4>Education </h4>
                            <p>Education socializes children into society by teaching cultural values and norms. It equips them with the skills needed to become productive members of society. 
                            <div style={{textAlign:"end"}}>
                                <a style={{color:"orange"}} href="#">read more</a>
                            </div>
                            </p>
                        </div>
                    </div>
                </Col>


                <Col>
                    <div className='visacard-card'>
                        <div className='visacardImg'>
                            <img className='img-fluid' width="50%" src={culp} alt="education card" />
                        </div>
                        <div>
                            <h4>Cultural Promotion</h4>
                            <p>It's stimulates the work of people in the field of culture through scholarships, technological platforms and socio-educational processes that lead promotion

                            <div style={{textAlign:"end"}}>
                                <a style={{color:"orange"}} href="#">read more</a>
                            </div>
                            </p>
                        </div>
                    </div>
                </Col>


                <Col>
                    <div className='visacard-card'>
                        <div className='visacardImg'>
                            <img className='img-fluid' width="50%" src={youi} alt="education card" />
                        </div>
                        <div>
                            <h4>Youth Idea London</h4>
                            <p>Thousands of young people have already shared their ideas on how to contribute to a better London.We want to promote transcultural exchange.
                            <div style={{textAlign:"end"}}>
                                <a style={{color:"orange"}} href="#">read more</a>
                            </div>
                                 </p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
    );
};

export default Involvecarts;